<template>
  <div />
</template>

<script>
export default {
  mounted () {
    const hash = window.location.hash.substr(1)

    // Convert hash parameters to key/value pairs
    const result = hash.split('&').reduce(function (res, item) {
      const parts = item.split('=')
      res[parts[0]] = parts[1]
      return res
    }, {})

    const token = result.id_token
    if (token) {
      window.location.href = `
        eddi://login?id_token=${token}
      ` // Use the app protocol you specified before
    }
  }
}
</script>
